<template>
  <section class="w-100 collectionProducts" style="padding: 15px 10px;">
    <div class="row mb-3">
      <div class="col-12 d-flex back-button align-items-center" @click="$emit('backFromVariant','')" style="cursor:pointer">
        <i class="fa-solid mr-2 fa-chevron-left"></i>
        <div>Back to Collections</div>
      </div>
    </div>
    <div class=" row questions-not-font flex-column d-flex justify-content-center align-items-center"
      v-if="areNotAvailableProducts">
    <LazyImage :src="'/assets/img/noData.svg'" alt="" style="width: 15rem;" class="mt-5" />
      <div class="row text-center px-2">
        <div class="col-12">
         <h3 class="pt-5" style="   font-family: 'Poppins';
font-style: normal;
font-weight: 500;
font-size: 18px;
line-height: 21px;

letter-spacing: 0.04em;

color: #4D4950;">No products found for selected collection!</h3>
        </div>
        
      </div>

    </div>

   
    <div class="">
      <div class="collectionSearchInput col-12 row align-items-center mx-1 pr-3 py-1" v-if="!areNotAvailableProducts">
        <div style="cursor: pointer">
          <i class="fa fa-search" style="color: #6d6b6d" aria-hidden="true"></i>
        </div>
        <input type="text" class="mx-2 flex-grow-1" v-model="searchProduct" placeholder="Search for Products" />
      </div>
    
    </div>
    <div class="w-100 p-3 collectionBox" v-if="isLoadingProducts&&isNextCollectionProduct==null" style="border:none">
     <b-spinner small></b-spinner>
     Loading Products
    </div>
    <!--  -->
    <div class="w-100 m-3 collectionBox" ref="scrollArea" @scroll="handleScroll($event)" v-if="(!areNotAvailableProducts) && getProducts.length > 0" style="border:none">
      <div :class="['mb-2 py-1,collection-row']" v-for="collection,index in getProducts" :key="collection.id+'-'+index">
        <div class="d-flex align-items-center collection">
          <div class="d-flex col-8 px-0 align-items-center">
            <div class="">

            </div>
            <div class="img ml-2">
              <div v-if="checkFileType(collection.image) == 'video'">
                <video ref="videoRef" :src="`${collection.image}`" alt="" class="img-thumbnail mr-2" style="height: 50px; width: 50px; border-radius: 50%; object-fit: cover;" controls></video>
              </div>
              <LazyImage :src="collectionImage(collection.image)" :key="1" alt="" class="product-image" />
            </div>
            <div class="singleProduct d-flex align-items-center pl-2">
              <p class="title text-left">
                {{ collection.title }}
              </p>
            </div>
          </div>
        </div>
      </div>
       <div class="d-flex justify-content-center align-items-center" v-if="isLoadingProducts" style="border:none">
     <b-spinner small></b-spinner>
     <span class="px-2">
       More Products...
     </span>
    </div>
    </div>
    
    <div class="w-100 p-3 collectionBox" v-if="!this.isLoadingProducts && collectionProducts.length > 0 && getProducts <= 0" style="border:none">
      <div class="d-flex flex-column h-100 justify-content-center align-items-center text-center mb-2 py-1">
        <div class="btn" v-b-tooltip.hover.top="'Reset Filter'" @click="searchProduct=''"> <i
            class="fa-solid fa-arrow-rotate-right"></i></div>
        <h5>No collectionProducts were found matching your filter </h5>
      </div>
    </div>
  </section>
</template>
<script>


export default {
  props: {
    collectionProducts: Array,
    selected: Object,
    isLoadingProducts: Boolean,
    isNextCollectionProduct:String||null
  },
  computed: {
    areNotAvailableProducts() {
      return this.isLoadingProducts == false && this.collectionProducts.length <= 0 ? true : false
    },
    checkReset() {

      for (let index in this.collectionFilters) {
        if (index != 'isFilterVisible') {
           // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          if (this.collectionFilters[index].selectedOPtion != null)  this.showResetBtn = true
        }
      }
      if (this.collectionFilters.price.above != '' || this.collectionFilters.price.start != '' || this.collectionFilters.price.end != '')
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.showResetBtn = true
      return this.showResetBtn
    },

    getProducts() {
      return this.collectionProducts.filter(item => {
        return (item.title.toLowerCase().includes(this.searchProduct.toLowerCase()))
      })

    }
  },
  watch: {
 
  },
  data() {
    return {
      skeletonCount: 5,
      searchProduct: '',
      isSelectAll: true,
      showResetBtn: false,
      collectionFilters: {
        price: {
          isActive: false,
          selectedOption: null,
          above: '',
          start: '',
          end: ''
        },
        tags: {
          isActive: false,
          allTags: [],
          selectedOPtion: null,
        },
        vendors: {
          isActive: false,
          selectedOPtion: null,
          allVendors: [],
        },
        collectionProducts: {
          isActive: false,
          allCollections: [],
          selectedOPtion: null,
        },
        isFilterVisible: false
      }

    }
  },
  methods: {
handleScroll({ target: { scrollTop, clientHeight, scrollHeight }}) {
      if ((scrollTop + clientHeight)+1 >= scrollHeight&&(!this.isLoadingProducts)&&(this.isNextCollectionProduct)) {
        this.$emit('openVariant');
      }
    },
  
    checkFileType(file) {
      let fileType;
      let allowedImageExtensions = /(\.jpg|\.jpeg|\.png|\.gif)$/i;
      let allowedVideoExtensions = /(\.mp4)$/i;

      if (!allowedImageExtensions.exec(file)) {
        if (allowedVideoExtensions.exec(file)) {
          fileType = "video";
        } else {
          fileType = "unknown";
        }
      } else {
        fileType = "image";
      }

      return fileType;
    },
    collectionImage(image) {
      if (image&& (!Array.isArray(image)))
        return image.startsWith('upload') ? '/' + image : image
      return 'https://images.quizell.com/default/default_image.png'
    }
  }
}
</script>
<style >
.collection-image {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  object-fit: cover;
  background-size: cover;
  padding: 0.25rem;
  background-color: #fff;
}

.collection-icon {
  color: #4d1b7e;

}

.collection-icon:hover {
  color: black;
}


.collectionSearchInput {
  border: 0.8px solid #18191C14;
  box-sizing: border-box;
  border-radius: 6px;
  background: #ffffff;
}

.collectionSearchInput input {
  background: transparent;
  border: none;
  outline: none;
}

.collectionSearchInput input:focus {
  border: none;
  outline: none;
}

.filterBtn {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  align-items: center;
  letter-spacing: 0.04em;
  color: #4d1b7e;
  cursor: pointer
}

.filterBtn:hover {
  color: black;

}

.w-86 {
  width: 86%;
}

.collectionBox {
  height: 300px;
  /* border: 0.5px solid #b3afb6; */
  box-sizing: border-box;
  border-radius: 10px;
  overflow-y: scroll;
  scroll-behavior: smooth;
}

.collectionBox .collection {
  border-radius: 6px;
}

.collectionBox .collection:hover {
  background: #b3afb614;
}

.variant-btn {
  display: none;
  font-size: 14px;
  border: 1px solid #b3afb6;
  border-radius: 12px;
  outline: none
}

.selectedProduct .collection:hover .variant-btn {
  border: 1.5px solid #4d1b7e;
  color: #4d1b7e !important;
}

.selectedProduct .collection:hover .variant-btn .variant-text {
  color: #4d1b7e !important;
}

.collection:hover .variant-btn {
  display: block;
}

.singleProduct .title {
  color: #18191c;
  font-weight: 500;
}

.singleProduct .price {
  color: #4d4950;
  font-weight: 300;
}
</style>